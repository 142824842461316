<template>
    <b-card>
        <b-row class="justify-content-center">
            <v-select class="col-md-6" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="option" placeholder="Select the Level..." />
        </b-row>
        <b-row class="justify-content-center mt-2">
            <span>Go to <a href="https://admob.google.com/">Google-adds-mob </a></span>
        </b-row>
        <b-row class="justify-content-center">
            <b-button variant="gradient-danger" class="mt-2 btn-icon"> Submit </b-button>
        </b-row>
    </b-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import { BFormDatepicker, BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButtonGroup, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BDropdown, BDropdownItem, BBadge } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";

const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BTab,
        BFormInput,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BBadge,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        apexchart: VueApexCharts,
        flatPickr,
        BFormDatepicker,
        BButtonGroup,
        BDropdown,
        BDropdownItem,
    },
    directives: {
        Ripple,
    },
    setup() {
        const option = ref(["high", "medium", "low"]);
        return {
            option,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#sub {
    margin-top: 4px;
}
</style>
